import 'core-js';
import StorefrontPageBase from 'oneapp/src/pages/storefrontPage';
import LockedAreaAuthorization from 'oneapp/src/components/LockedAreaAuthorization';

class StorefrontPage extends StorefrontPageBase {
	init() {
		super.init();

		document.addEventListener('DOMContentLoaded', () => {
			if (app.page.pageData && app.page.pageData.isLockedAreaAuthorization && !app.currentCustomer.isAuthenticated()) {
				const popupVariation = 'secondary';
				const lockedAreaAuthorization = new LockedAreaAuthorization(app.preferences.lockedEditorialPagesTiming, popupVariation);

				lockedAreaAuthorization.init();
			}
		});
	}
}

new StorefrontPage().init();
